<template>
    <Page icon="mdi-home" title="Unsupported Browser Detected">
        <v-card>
            <v-card-text>
                Please use one of the following browsers:
                <v-list>
                    <v-list-item>
                        <v-row>
                            <v-col>
                                <a href="https://www.google.com/intl/en_au/chrome/" target="_blank"> Chrome </a>
                            </v-col>
                            <v-col>
                                (preferred)
                            </v-col><v-spacer></v-spacer>
                            <v-col></v-col>

                        </v-row>
                    </v-list-item>
                    <v-list-item>
                        <v-row>
                            <v-col>
                                <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank"> FireFox </a>
                            </v-col>
                        </v-row>
                    </v-list-item>
                    <v-list-item>
                        Safari
                    </v-list-item>
                    <v-list-item>
                        Edge
                    </v-list-item>

                </v-list>
            </v-card-text>
        </v-card>
    </Page>
</template>

<script>
export default {
    name: 'BadBrowser',

    data() {
        return {
            unsupportedBrowser: false,
            isNotRecommendedBrowser: true,
        };
    },
    methods: {

    },
    mounted() {
    },
};
</script>

<style scoped>
a {  color: whitesmoke;}
</style>
